// Register the service worker
export async function registerServiceWorker() {
  try {
    if ("serviceWorker" in navigator) {
      await navigator.serviceWorker.register("./sw.js", {
        scope: "/",
      });

      await navigator.serviceWorker.ready;

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        console.log("[Service Worker] Registered");
      }
    }
  } catch (err) {
    console.log("[Service Worker] Registration Error:", err);
  }
}

// Simple push tutorial
//https://dev.to/wteja/how-to-make-push-notification-using-nodejs-and-service-worker-jaa

// Other Simple web push tutorial
// https://thecodebarbarian.com/sending-web-push-notifications-from-node-js.html

// Very complete web puh tutorial
// https://web.dev/sending-messages-with-web-push-libraries/

// Register the notifcations system
export async function registerForPushNotifications(appCheckToken, accessToken) {
  try {
    // Create VAPIDKEY
    // https://vapidkeys.com/
    const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;

    if ("serviceWorker" in navigator) {
      const register = await navigator.serviceWorker.ready;

      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/notifications/subscribe`,
        {
          method: "POST",
          body: JSON.stringify(subscription),
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            "X-Firebase-AppCheck": appCheckToken,
          },
        }
      );

      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        if (response.status === 304) {
          console.log("[Web Push] Not Modified (304)");
        } else if (response.ok) {
          console.log("[Web Push] Registered");
        } else {
          console.log("[Web Push] Registration Error:", response.status);
        }
      }
    }
  } catch (err) {
    console.log("[Web Push] Registration Error:", err);
  }
}
