import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { getSilos, GetConfiguration } from "../services/apiService";
import { Loading } from "../components";
import axios from "axios";
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import { getToken } from "firebase/app-check";
import { appCheck, auth } from "../services/firebaseConfig";
import { registerForPushNotifications } from "../swDev";
import { format, min } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  // Logged user object with all metadata
  const [user, setUser] = useState(null);
  // Silos list
  const [silos, setSilos] = useState([]);
  // Full page loading
  const [isLoading, setIsLoading] = useState(true);
  // Offline flag
  const [offline, setOffline] = useState(false);
  // Notifications lists
  const [notifications, setNotifications] = useState([]);
  // Notifications dot flag
  const [newNotification, setNewNotification] = useState(false);
  // Configuration
  const [configuration, setConfiguration] = useState(null);

  // Broadcast Channel for Service Worker new notification message
  const notificationsChannelRef = useRef(new BroadcastChannel("notifications"));
  const notificationsChannel = notificationsChannelRef.current;

  // Manually logout user
  const logout = () => {
    // Empty local storage
    localStorage.removeItem("notifications");
    localStorage.removeItem("silos");
    // Logout the user
    return signOut(auth);
  };

  // Create Axios instance utility for API calls
  const createAxiosInstance = useCallback(async () => {
    try {
      // Check app token and refresh
      const appCheckTokenResponse = await getToken(appCheck, false);
      // Check user token and refresh
      const token = await getIdToken(user);

      // Create axios instance
      const instance = axios.create({
        headers: {
          "X-Firebase-AppCheck": appCheckTokenResponse.token,
          authorization: `Bearer ${token}`,
        },
      });

      // Interceptor for all the responses
      instance.interceptors.response.use(
        (response) => response,
        (error) => {
          return Promise.reject(error);
        }
      );

      return instance;
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  // Call sw push notification subscription function
  const handlePushNotificationSubscription = async () => {
    // Check app token and refresh
    const appCheckTokenResponse = await getToken(appCheck, false);
    // Check user token and refresh
    const accessToken = await getIdToken(auth.currentUser);

    if (accessToken) {
      // Register the sw to the notifications service
      await registerForPushNotifications(appCheckTokenResponse.token, accessToken);
    }
  };

  // Format timestamp
  const formatTimestamp = (utcIso, timezone, formatString) => {
    const timestamp = utcToZonedTime(new Date(utcIso), timezone);
    return format(timestamp, formatString);
  }

  // Convert seconds hours, minutes and seconds
  const convertFromSeconds = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    // Return values
    return { hours, minutes, seconds: secondsLeft };
  };

  // Convert hours, minute and seconds to total seconds
  const convertToSeconds = ( hours, minutes, seconds ) => {
    return hours * 3600 + minutes * 60 + seconds;
  };
  
  // Update user if the state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // Fetch configuration and silos list
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Create axios instance
        const api = await createAxiosInstance();

        // Fetch webapp configuration
        const fetchedConfiguration = await GetConfiguration(api);
        setConfiguration(fetchedConfiguration);

        // Fetch silos list
        const fetchedSilos = await getSilos(api);

        // Store silos in local storage
        localStorage.setItem("silos", JSON.stringify(fetchedSilos));
        setSilos(fetchedSilos);
      } catch (error) {
        // Use local storage silos
        setSilos(JSON.parse(localStorage.getItem("silos")) || []);
        setOffline(true);
      } finally {
        // Disable loading
        setIsLoading(false);
      }
    };

    if (user) {
      setIsLoading(true);
      fetchData();
    }
  }, [createAxiosInstance, user]);

  // Handle new notifications
  useEffect(() => {
    notificationsChannel.onmessage = (event) => {
      // Extract notification
      const notificationData = event.data;
      let storedNotifications = [];

      // Get stored notifications
      const storedNotificationsJSON = localStorage.getItem("notifications");
      if (storedNotificationsJSON) {
        storedNotifications = JSON.parse(storedNotificationsJSON);
      }

      // Add the new notification to the array
      storedNotifications.push(notificationData);

      // Check if the number of notifications exceeds the limit
      if (storedNotifications.length > 5) {
        // Remove the oldest notification (the first one in the array)
        storedNotifications.shift();
      }

      // Set notifications var state
      setNotifications(storedNotifications);

      // Store the updated notifications array back in localStorage
      window.localStorage.setItem(
        "notifications",
        JSON.stringify(storedNotifications)
      );

      // Set new notification var state
      setNewNotification(true);
    };
  }, [notificationsChannel]);

  // If is loading show Loading component
  if (isLoading) {
    return <Loading />;
  }

  return (
    <StateContext.Provider
      value={{
        user,
        logout,
        offline,
        setOffline,
        notifications,
        setNotifications,
        newNotification,
        setNewNotification,
        silos,
        configuration,
        createAxiosInstance,
        handlePushNotificationSubscription,
        formatTimestamp,
        convertFromSeconds,
        convertToSeconds
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
